video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url('/images/mixerbg1.jpg') center center/cover no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  justify-content: flex-start;
  /* align-items: left; */
  /* align-items: stretch; */
  text-align: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  font-family: 'League Gothic', sans-serif;
  padding-top: 25vh;
  border: 1px solid white;
}

.hero-container > h1 {
  color: #fff;
  font-size: 125px;
  margin-top: -70px;
  text-align: center;
  /* padding-left: 50px; */
  font-family: 'League Gothic', sans-serif;
}

.hero-container > h2 {
  font-size: 32px;
  margin-top: 8px;
  color: #fff;
}

.hero-container > p {
  font-size: 20px;
  /* margin-top: 8px; */
  text-align: center;
  color: #fff;
  margin-bottom: auto;
}

.hero-btns {
  margin-top: 32px;
  /* padding-left: 50px; */
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-container > p {
	  font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 55px;
    margin-top: -100px;
	padding-left: 10px;
  }

  .hero-container > h2 {
    font-size: 20px;
	padding-left: 10px;
  }

  /*
  .hero-container > p {
    font-size: 12px;
	margin-bottom: 0;
  }
  */

  .btn-mobile {
    display: block;
    text-decoration: none;
	width: 90%;
	padding-left: 0;
  }

  .btn {
    width: 100%;
  }
}

.spacer {
	height: 40%;
}
