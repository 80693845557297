.booking-section {
  background: url('/images/villebgB2.jpg') center center/cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  font-family: 'League Gothic', sans-serif;
  border: 2px solid white;
  color: #fff;
}

.booking-section > h1 {
  color: #fff;
  font-size: 125px;
  text-align: center;
  font-family: 'League Gothic', sans-serif;
}

.booking-section > h2 {
  font-size: 32px;
  text-align: center;
  color: #fff;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', */
    /* 'Lucida Sans', Arial, sans-serif; */
}

.booking-section > p {
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-left: 150px;
  margin-right: 150px;
  text-align: center;
  color: #fff;
}

.booking-form {
	font-size: 3vw;
	text-align: center;
	margin-bottom: -28px;
}

.booking-form > p {
	font-size: 18px;
	text-align: left;
}

.booking-form > h1 {
	font-size: 48px;
	text-align: left;
}

.booking-form > h2 {
	font-size: 32px;
	text-align: left;
}

.booking-form > h3 {
	font-size: 24px;
	text-align: left;
}

.booking-room {
  background: url('/images/x32bgdark.jpg') center center/cover no-repeat;
  height: 5vh;
}


@media screen and (max-width: 2000px) {
	.booking-form {
		width: 50%;
	}
}

@media screen and (max-width: 1200px) {
	.booking-form {
	  width: 70%;
	}
}

@media screen and (max-width: 768px) {
  .booking-section > h1 {
    font-size: 50px;
  }

  .booking-section > h2 {
	  font-size: 20px;
  }

  .booking-section > p {
    font-size: 14px;
  }

  .booking-form {
	  width: 95%;
  }

  .close_button {
	  width: 10%;
  }
}

